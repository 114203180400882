import {
  get as _get,
  has as _has,
  includes as _includes,
  isObjectLike as _isObjectLike,
  last as _last,
  map as _map,
  toLower as _toLower
} from 'lodash-es'
import {Component} from 'react'
import * as config from '../config'
import {Utility} from './Utility'

const axios = require('axios').default

export interface UIResponse {
  data?: any
  errors?: string[]
  successes?: string[]
}

export interface Error {
  serial: string
  code: string
  message: string
}

export interface RemotePatchNormalResponse {
  totalCount: number
  successCount: number
  failureCount: number
  errors?: Error[]
}

export interface RemotePatchValidationResponse {
  data?: null | any
  // e.g. invalid_arguments
  errorType: string
  // e.g. `[1]: \"items[0].serial\" length must be at least 14 characters long`
  errorMessage: string
}

/* Axios response, e.g. from a remote PATCH to /devices */
export interface RemoteResponse {
  data: RemotePatchValidationResponse | RemotePatchNormalResponse
  config: any
  headers: any
  request: any
  status: number
  statusText: string
}

class RemoteData extends Component {
  /**
   * Synchronous access to token
   */
  static getToken = () => {
    // AWS stored in local storage CognitoIdentityServiceProvider['client_id'][...uuid].idToken
    const prefix = `CognitoIdentityServiceProvider.${config.amplify.aws_user_pools_web_client_id}`
    const lastAuthUser = _get(window, ['localStorage', `${prefix}.LastAuthUser`], '')
    const token = _get(window, ['localStorage', `${prefix}.${lastAuthUser}.idToken`], '')

    return token
  }

  /**
   * Synchronous access to API KEY
   */
  static getKey = () => {
    return _get(window, ['localStorage', 'apiKey'], '')
  }

  /**
   * Given an error message such as '[1]: "items[0].serial" length must be at least 14 characters long',
   * return a friendlier error message with the proper row number such as:
   * '[1]: "Row 1 serial" length must be at least 14 characters long'
   *
   * @param error
   */
  static formatErrorMessage = (error: string) => {
    const replacer = (match, row, offset, string) => {
      const rowPlus1 = +row + 1
      return `Row ${rowPlus1} `
    }
    error = error.replace(/items\[(\d)\]\./gi, replacer)

    return error
  }

  /**
   * Get full API URL
   *
   * @param relativeUrl - e.g. '/device'
   */
  static getAPIUrl = (relativeUrl: string) => {
    const baseUrl = Utility.envVariable('REACT_APP_PARTNER_API', false, 'https://zto.poly.com/api/v1')
    return baseUrl + relativeUrl
  }

  /**
   * @param relativeUrl - relative URL, will append to base API URL; e.g. '/device'
   * @param method - patch, post, put, delete, get etc
   * @param data - defaults to {}
   * @param setResponse - function to call with response errors and successes, e.g. {successes: [...], errors: [...]}
   * @param noun - e.g. 'device'
   * @param verb - e.g. 'configure'
   */
  static call = (relativeUrl: string, method: string, data: any = {}, setResponse: any, noun: string, verb: string) => {
    const url = RemoteData.getAPIUrl(relativeUrl)
    // cast method (get, put, post, patch etc) to lowercase
    method = _toLower(method)

    let config: any = {
      url,
      method,
      headers: {
        'X-API-KEY': RemoteData.getKey(),
        Authorization: `Bearer ${RemoteData.getToken()}`
      }
    }

    // if mutating remote data, ensure data is similar to {} or [] before adding config
    if (_includes(['patch', 'post', 'put', 'delete'], method) && _isObjectLike(data)) {
      config.headers['Content-Type'] = 'application/json'
      config.data = data
    }

    try {
      axios(config)
        .then((res: RemoteResponse) => {
          RemoteData.handleResponse(res, noun, verb, setResponse)
        })
        .catch((res: RemoteResponse) => {
          // would help to create typing specific to RemoteErrorResponse,
          // for now using RemoteResponse as it should be similar
          RemoteData.handleResponse(res, noun, verb, setResponse)
        })
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Handle API response, giving the user notification of success or failure.
   *
   * @param res
   * @param noun
   * @param verb
   * @param setResponse
   */
  static handleResponse(res: RemoteResponse, noun: string, verb: string, setResponse: any): void {
    // simple, for now:
    // update -> updated, create -> created, delete -> deleted, remove -> removed
    // connect -> connected
    // device -> devices (plural)
    const verb_past_tense = 'e' === _last(verb) ? `${verb}d` : `${verb}ed`
    const noun_plural = `${noun}s`

    let responseData, noun_word
    let response: UIResponse = {}

    // handle RemotePatchNormalResponse first
    if (200 === _get(res, 'status') && _has(res, 'data.totalCount')) {
      // at this point, can rely on RemotePatchNormalResponse schema
      responseData = res.data as RemotePatchNormalResponse

      response = {}
      if (responseData.successCount > 0) {
        noun_word = responseData.successCount > 1 ? noun_plural : noun
        response.successes = [`Successfully ${verb_past_tense} ${responseData.successCount} ${noun_word}.`]
      }
      if (responseData.failureCount > 0) {
        noun_word = responseData.failureCount > 1 ? noun_plural : noun
        response.errors = [`Failed to ${verb} ${responseData.failureCount} ${noun_word}.`]
      }
    } else if (_has(res, 'data.errorMessage')) {
      // NOTE responses with data.errorMessage may have a status of 200 (e.g. validation issue) or 4xx
      // if validation error, there will only be one (in other words, it will stop processing after one validation error)
      responseData = res.data as RemotePatchValidationResponse

      response = {
        errors: [
          `Failed to ${verb} some or all ${noun_plural}. Error: ` +
            RemoteData.formatErrorMessage(responseData.errorMessage)
        ]
      }
    } else {
      // handle generic and specific non-200 responses
      let error = `Unable to ${verb} ${noun_plural}.`
      if (_has(res, 'data.errorMessage')) {
        responseData = res.data as any
        error += ' Received message: ' + RemoteData.formatErrorMessage(responseData.errorMessage)
      }

      response = {
        errors: [error]
      }
    }

    if (_has(res, 'data.errors')) {
      // include text from all errors
      response.errors = response.errors || []
      const errorTextArray = _map((res as any).data.errors, (error: Error, i: number) => {
        return `[Error ${i + 1}] Device Serial ${error.serial}, internal code "${error.code}". ${error.message}`
      })

      response.errors = [...response.errors, ...errorTextArray]
    }

    setResponse(response)
  }
}

export {RemoteData}
