import {find as _find, get as _get, map as _map, memoize as _memoize, trim as _trim} from 'lodash-es'
import React, {ReactElement} from 'react'
import EightXEight from '../../assets/vendor-images/8x8.png'
import GoToMeeting from '../../assets/vendor-images/go-to-meeting.png'
import MicrosoftTeams from '../../assets/vendor-images/microsoft-teams.png'
import RingCentral from '../../assets/vendor-images/ring-central.png'
import BlueJeans from '../../assets/vendor-images/bluejeans.png'
import Dialpad from '../../assets/vendor-images/dialpad.png'
import Zoom from '../../assets/vendor-images/zoom.png'
import Poly from '../../assets/vectors/poly-light-bg.svg'
import StarLeaf from '../../assets/vendor-images/starleaf.png'
import Tencent from '../../assets/vendor-images/tencent.png'
import GoogleMeet from '../../assets/vendor-images/googlemeet.png'
import {Utility} from '../../utils/Utility'

// virtual bundle number mapping source is an internal Excel doc
const bundleTypes = [
  ['Poly Studio X30 Systems - Zoom', '623086480001'],
  ['Poly Studio X30 Systems - Zoom', '623086480002'],
  ['Poly Studio X30 Systems - Zoom', '623086480009'],
  ['Poly Studio X30 Systems - Zoom', '623086480012'],
  ['Poly Studio X30 Systems - Zoom', '623086480016'],
  ['Poly Studio X30 Systems - Zoom', '623086480022'],
  ['Poly Studio X30 Systems - Zoom', '623086480023'],
  ['Poly Studio X30 Systems - Zoom', '623086480034'],
  ['Poly Studio X30 Systems - Zoom', '623086480036'],
  ['Poly Studio X30 Systems - Zoom', '623086480101'],
  ['Poly Studio X30 Systems - Zoom', '623086480102'],
  ['Poly Studio X30 Systems - Zoom', '623086480114'],
  ['Poly Studio X30 Systems - Zoom', '623086480119'],
  ['Poly Studio X30 Systems - Zoom', '623086480212'],
  ['Poly Studio X30 with Poly TC8 - Zoom', '623086490001'],
  ['Poly Studio X30 with Poly TC8 - Zoom', '623086490002'],
  ['Poly Studio X30 with Poly TC8 - Zoom', '623086490009'],
  ['Poly Studio X30 with Poly TC8 - Zoom', '623086490012'],
  ['Poly Studio X30 with Poly TC8 - Zoom', '623086490016'],
  ['Poly Studio X30 with Poly TC8 - Zoom', '623086490022'],
  ['Poly Studio X30 with Poly TC8 - Zoom', '623086490023'],
  ['Poly Studio X30 with Poly TC8 - Zoom', '623086490034'],
  ['Poly Studio X30 with Poly TC8 - Zoom', '623086490036'],
  ['Poly Studio X30 with Poly TC8 - Zoom', '623086490101'],
  ['Poly Studio X30 with Poly TC8 - Zoom', '623086490102'],
  ['Poly Studio X30 with Poly TC8 - Zoom', '623086490114'],
  ['Poly Studio X30 with Poly TC8 - Zoom', '623086490119'],
  ['Poly Studio X30 with Poly TC8 - Zoom', '623086490212'],
  ['Poly Studio X50 Systems - Zoom', '623086500001'],
  ['Poly Studio X50 Systems - Zoom', '623086500002'],
  ['Poly Studio X50 Systems - Zoom', '623086500009'],
  ['Poly Studio X50 Systems - Zoom', '623086500012'],
  ['Poly Studio X50 Systems - Zoom', '623086500016'],
  ['Poly Studio X50 Systems - Zoom', '623086500022'],
  ['Poly Studio X50 Systems - Zoom', '623086500023'],
  ['Poly Studio X50 Systems - Zoom', '623086500034'],
  ['Poly Studio X50 Systems - Zoom', '623086500036'],
  ['Poly Studio X50 Systems - Zoom', '623086500101'],
  ['Poly Studio X50 Systems - Zoom', '623086500102'],
  ['Poly Studio X50 Systems - Zoom', '623086500114'],
  ['Poly Studio X50 Systems - Zoom', '623086500119'],
  ['Poly Studio X50 Systems - Zoom', '623086500212'],
  ['Poly Studio X50 with Poly TC8 - Zoom', '623086510001'],
  ['Poly Studio X50 with Poly TC8 - Zoom', '623086510002'],
  ['Poly Studio X50 with Poly TC8 - Zoom', '623086510009'],
  ['Poly Studio X50 with Poly TC8 - Zoom', '623086510012'],
  ['Poly Studio X50 with Poly TC8 - Zoom', '623086510016'],
  ['Poly Studio X50 with Poly TC8 - Zoom', '623086510022'],
  ['Poly Studio X50 with Poly TC8 - Zoom', '623086510023'],
  ['Poly Studio X50 with Poly TC8 - Zoom', '623086510034'],
  ['Poly Studio X50 with Poly TC8 - Zoom', '623086510036'],
  ['Poly Studio X50 with Poly TC8 - Zoom', '623086510101'],
  ['Poly Studio X50 with Poly TC8 - Zoom', '623086510102'],
  ['Poly Studio X50 with Poly TC8 - Zoom', '623086510114'],
  ['Poly Studio X50 with Poly TC8 - Zoom', '623086510119'],
  ['Poly Studio X50 with Poly TC8 - Zoom', '623086510212'],
  ['G7500 System - Zoom', '623086520001'],
  ['G7500 System - Zoom', '623086520002'],
  ['G7500 System - Zoom', '623086520009'],
  ['G7500 System - Zoom', '623086520012'],
  ['G7500 System - Zoom', '623086520016'],
  ['G7500 System - Zoom', '623086520022'],
  ['G7500 System - Zoom', '623086520023'],
  ['G7500 System - Zoom', '623086520034'],
  ['G7500 System - Zoom', '623086520036'],
  ['G7500 System - Zoom', '623086520101'],
  ['G7500 System - Zoom', '623086520102'],
  ['G7500 System - Zoom', '623086520114'],
  ['G7500 System - Zoom', '623086520119'],
  ['G7500 System - Zoom', '623086520212'],
  ['G7500 Kit with EagleEye Cube USB - Zoom', '623086525001'],
  ['G7500 Kit with EagleEye Cube USB - Zoom', '623086525002'],
  ['G7500 Kit with EagleEye Cube USB - Zoom', '623086525009'],
  ['G7500 Kit with EagleEye Cube USB - Zoom', '623086525012'],
  ['G7500 Kit with EagleEye Cube USB - Zoom', '623086525016'],
  ['G7500 Kit with EagleEye Cube USB - Zoom', '623086525022'],
  ['G7500 Kit with EagleEye Cube USB - Zoom', '623086525023'],
  ['G7500 Kit with EagleEye Cube USB - Zoom', '623086525034'],
  ['G7500 Kit with EagleEye Cube USB - Zoom', '623086525036'],
  ['G7500 Kit with EagleEye Cube USB - Zoom', '623086525101'],
  ['G7500 Kit with EagleEye Cube USB - Zoom', '623086525102'],
  ['G7500 Kit with EagleEye Cube USB - Zoom', '623086525114'],
  ['G7500 Kit with EagleEye Cube USB - Zoom', '623086525119'],
  ['G7500 Kit with EagleEye Cube USB - Zoom', '623086525212'],
  ['G7500 Kit with EagleEyeIV-12x - Zoom', '623086535001'],
  ['G7500 Kit with EagleEyeIV-12x - Zoom', '623086535002'],
  ['G7500 Kit with EagleEyeIV-12x - Zoom', '623086535009'],
  ['G7500 Kit with EagleEyeIV-12x - Zoom', '623086535012'],
  ['G7500 Kit with EagleEyeIV-12x - Zoom', '623086535016'],
  ['G7500 Kit with EagleEyeIV-12x - Zoom', '623086535022'],
  ['G7500 Kit with EagleEyeIV-12x - Zoom', '623086535023'],
  ['G7500 Kit with EagleEyeIV-12x - Zoom', '623086535034'],
  ['G7500 Kit with EagleEyeIV-12x - Zoom', '623086535036'],
  ['G7500 Kit with EagleEyeIV-12x - Zoom', '623086535101'],
  ['G7500 Kit with EagleEyeIV-12x - Zoom', '623086535102'],
  ['G7500 Kit with EagleEyeIV-12x - Zoom', '623086535114'],
  ['G7500 Kit with EagleEyeIV-12x - Zoom', '623086535119'],
  ['G7500 Kit with EagleEyeIV-12x - Zoom', '623086535212'],
  ['G7500 System with TC8 - Zoom', '623086540001'],
  ['G7500 System with TC8 - Zoom', '623086540002'],
  ['G7500 System with TC8 - Zoom', '623086540009'],
  ['G7500 System with TC8 - Zoom', '623086540012'],
  ['G7500 System with TC8 - Zoom', '623086540016'],
  ['G7500 System with TC8 - Zoom', '623086540022'],
  ['G7500 System with TC8 - Zoom', '623086540023'],
  ['G7500 System with TC8 - Zoom', '623086540034'],
  ['G7500 System with TC8 - Zoom', '623086540036'],
  ['G7500 System with TC8 - Zoom', '623086540101'],
  ['G7500 System with TC8 - Zoom', '623086540102'],
  ['G7500 System with TC8 - Zoom', '623086540114'],
  ['G7500 System with TC8 - Zoom', '623086540119'],
  ['G7500 System with TC8 - Zoom', '623086540212'],
  ['G7500 Kit with EagleEye Cube USB and TC8 - Zoom', '623086545001'],
  ['G7500 Kit with EagleEyeIV-4x and TC8 - Zoom', '623086550001'],
  ['G7500 Kit with EagleEyeIV-4x and TC8 - Zoom', '623086550002'],
  ['G7500 Kit with EagleEyeIV-4x and TC8 - Zoom', '623086550009'],
  ['G7500 Kit with EagleEyeIV-4x and TC8 - Zoom', '623086550012'],
  ['G7500 Kit with EagleEyeIV-4x and TC8 - Zoom', '623086550016'],
  ['G7500 Kit with EagleEyeIV-4x and TC8 - Zoom', '623086550022'],
  ['G7500 Kit with EagleEyeIV-4x and TC8 - Zoom', '623086550023'],
  ['G7500 Kit with EagleEyeIV-4x and TC8 - Zoom', '623086550034'],
  ['G7500 Kit with EagleEyeIV-4x and TC8 - Zoom', '623086550036'],
  ['G7500 Kit with EagleEyeIV-4x and TC8 - Zoom', '623086550101'],
  ['G7500 Kit with EagleEyeIV-4x and TC8 - Zoom', '623086550102'],
  ['G7500 Kit with EagleEyeIV-4x and TC8 - Zoom', '623086550114'],
  ['G7500 Kit with EagleEyeIV-4x and TC8 - Zoom', '623086550119'],
  ['G7500 Kit with EagleEyeIV-4x and TC8 - Zoom', '623086550212'],
  ['G7500 Kit with EagleEyeIV-12x and TC8 - Zoom', '623086555001'],
  ['G7500 Kit with EagleEyeIV-12x and TC8 - Zoom', '623086555002'],
  ['G7500 Kit with EagleEyeIV-12x and TC8 - Zoom', '623086555009'],
  ['G7500 Kit with EagleEyeIV-12x and TC8 - Zoom', '623086555012'],
  ['G7500 Kit with EagleEyeIV-12x and TC8 - Zoom', '623086555016'],
  ['G7500 Kit with EagleEyeIV-12x and TC8 - Zoom', '623086555022'],
  ['G7500 Kit with EagleEyeIV-12x and TC8 - Zoom', '623086555023'],
  ['G7500 Kit with EagleEyeIV-12x and TC8 - Zoom', '623086555034'],
  ['G7500 Kit with EagleEyeIV-12x and TC8 - Zoom', '623086555036'],
  ['G7500 Kit with EagleEyeIV-12x and TC8 - Zoom', '623086555101'],
  ['G7500 Kit with EagleEyeIV-12x and TC8 - Zoom', '623086555102'],
  ['G7500 Kit with EagleEyeIV-12x and TC8 - Zoom', '623086555114'],
  ['G7500 Kit with EagleEyeIV-12x and TC8 - Zoom', '623086555119'],
  ['G7500 Kit with EagleEyeIV-12x and TC8 - Zoom', '623086555212'],
  ['G7500 Kit with EagleEyeIV-4x - Zoom', '623086530001'],
  ['G7500 Kit with EagleEyeIV-4x - Zoom', '623086530002'],
  ['G7500 Kit with EagleEyeIV-4x - Zoom', '623086530009'],
  ['G7500 Kit with EagleEyeIV-4x - Zoom', '623086530012'],
  ['G7500 Kit with EagleEyeIV-4x - Zoom', '623086530016'],
  ['G7500 Kit with EagleEyeIV-4x - Zoom', '623086530022'],
  ['G7500 Kit with EagleEyeIV-4x - Zoom', '623086530023'],
  ['G7500 Kit with EagleEyeIV-4x - Zoom', '623086530034'],
  ['G7500 Kit with EagleEyeIV-4x - Zoom', '623086530036'],
  ['G7500 Kit with EagleEyeIV-4x - Zoom', '623086530101'],
  ['G7500 Kit with EagleEyeIV-4x - Zoom', '623086530102'],
  ['G7500 Kit with EagleEyeIV-4x - Zoom', '623086530114'],
  ['G7500 Kit with EagleEyeIV-4x - Zoom', '623086530119'],
  ['G7500 Kit with EagleEyeIV-4x - Zoom', '623086530212'],
  ['G7500 Kit with EagleEye Cube USB and TC8 - Zoom', '623086545002'],
  ['G7500 Kit with EagleEye Cube USB and TC8 - Zoom', '623086545009'],
  ['G7500 Kit with EagleEye Cube USB and TC8 - Zoom', '623086545012'],
  ['G7500 Kit with EagleEye Cube USB and TC8 - Zoom', '623086545016'],
  ['G7500 Kit with EagleEye Cube USB and TC8 - Zoom', '623086545022'],
  ['G7500 Kit with EagleEye Cube USB and TC8 - Zoom', '623086545023'],
  ['G7500 Kit with EagleEye Cube USB and TC8 - Zoom', '623086545034'],
  ['G7500 Kit with EagleEye Cube USB and TC8 - Zoom', '623086545036'],
  ['G7500 Kit with EagleEye Cube USB and TC8 - Zoom', '623086545101'],
  ['G7500 Kit with EagleEye Cube USB and TC8 - Zoom', '623086545102'],
  ['G7500 Kit with EagleEye Cube USB and TC8 - Zoom', '623086545114'],
  ['G7500 Kit with EagleEye Cube USB and TC8 - Zoom', '623086545119'],
  ['G7500 Kit with EagleEye Cube USB and TC8 - Zoom', '623086545212']
]

let customBundleTypeOptions = _map(bundleTypes, row => {
  const [label, value] = row

  // for partner usability, reformat label to have value first, then the label
  return {
    value,
    label: `${value} - ${label}`
  }
})

/**
 * @param image
 * @param alt - image alt tag
 * @param maxHeight - allow manual tweaking so that logos look more similarly sized
 */
const wrapImage = (image, alt = '', maxHeight = 'auto'): ReactElement => {
  return <img src={image} style={{maxHeight}} alt={alt} />
}

interface IBundleTypeOption {
  label: ReactElement
  value: string
  // formatted value for friendly (readable) text output
  readable: string
}

// NOTE these are ordered by approximate customer usage descending
const allBundleTypeOptions: IBundleTypeOption[] = [
  {
    label: wrapImage(Poly, 'Poly Logo', '35px'),
    value: 'poly_video',
    readable: 'Poly Video'
  },
  {
    label: wrapImage(Zoom, 'Zoom Logo', '23px'),
    value: 'zoom',
    readable: 'Zoom'
  },
  {
    label: wrapImage(MicrosoftTeams, 'Microsoft Teams Logo'),
    value: 'microsoft_teams',
    readable: 'Microsoft Teams'
  },
  {
    label: wrapImage(GoToMeeting, 'GoToMeeting Logo'),
    value: 'lmi_gotomeeting',
    readable: 'GoToMeeting'
  },
  {
    label: wrapImage(EightXEight, '8x8 Logo', '26px'),
    value: 'eight_eight',
    readable: '8x8'
  },
  {
    label: wrapImage(RingCentral, 'RingCentral Logo'),
    value: 'ring_central',
    readable: 'RingCentral'
  },
  {
    label: wrapImage(BlueJeans, 'BlueJeans Logo'),
    value: 'bluejeans',
    readable: 'BlueJeans'
  },
  {
    label: wrapImage(StarLeaf, 'StarLeaf Logo'),
    value: 'starleaf',
    readable: 'StarLeaf'
  },
  {
    label: wrapImage(Dialpad, 'Dialpad Logo'),
    value: 'dialpad',
    readable: 'Dialpad'
  },
  {
    label: wrapImage(Tencent, 'Tencent Logo'),
    value: 'tencent',
    readable: 'Tencent'
  },
  {
    label: wrapImage(GoogleMeet, 'Google Meet Logo'),
    value: 'google_meet',
    readable: 'Google Meet'
  },
  {
    label: <span className="button-list-text">Device Mode</span>,
    value: 'poly_device',
    readable: 'Device Mode'
  },
  {
    label: <span className="button-list-text">Bundle SKU</span>,
    // this option allows user to select from customBundleTypeOptions
    value: 'custom',
    readable: 'Selected Provider'
  }
]

const readableBundleMapping = {}
allBundleTypeOptions.map((bundleTypeOption: IBundleTypeOption) => {
  readableBundleMapping[bundleTypeOption.value] = bundleTypeOption.readable
})

const generateGenericOption = (label: string, value: string) => {
  return {
    label: <span className="button-list-text">{label}</span>,
    value,
    readable: Utility.defaultReadableConvert(value)
  }
}

/**
 * This function is memoized using lodash, because React.memo requires a React component
 * that returns a ReactElement.
 *
 * Note given the same inputs, this function will produce the same outputs because
 * Utility.getEnv() will not change based on app state changes.
 */
const generateBundleTypeOptions = _memoize((isEnabled: boolean, variables: Record<string, any>): IBundleTypeOption[] => {
  if (isEnabled && Object.keys(variables).length) {
    const providersEnabled = _get(variables, Utility.getEnv(), '')

    if (providersEnabled) {
      let providers = providersEnabled.split(',')
      providers = providers.map(_trim)

      const generatedBundleTypeOptions = providers.map(provider => {
        let bundleTypeOption: IBundleTypeOption | undefined = _find(allBundleTypeOptions, (option: IBundleTypeOption) => option.value === provider)
        if (!bundleTypeOption) {
          bundleTypeOption = generateGenericOption(Utility.providerReadable(provider), provider)
        }

        return bundleTypeOption
      })

      // if all items filtered out, assume there is an error and return allBundleTypeOptions
      if (generatedBundleTypeOptions.length) {
        return generatedBundleTypeOptions
      }
    }
  }

  return allBundleTypeOptions
})

/**
 * This function is memoized using lodash, because React.memo requires a React component
 * that returns a ReactElement.
 *
 * Note given the same inputs, this function will produce the same outputs because
 * Utility.getEnv() will not change based on app state changes.
 */
const isLockableProvider = (isEnabled: boolean, variables: Record<string, any>, selectedProvider?: string): boolean => {
  if (isEnabled && selectedProvider && Object.keys(variables).length) {
    // get lockableProvidersSetting based on this environment (development, staging, production)
    const lockableProvidersSetting = _get(variables, Utility.getEnv(), '')

    if (lockableProvidersSetting) {
      let lockableProviders = lockableProvidersSetting.split(',')
      lockableProviders = lockableProviders.map(_trim)

      return lockableProviders.includes(selectedProvider)
    }
  }

  // default case: consider no providers lockable
  return false
}

export {readableBundleMapping, customBundleTypeOptions, generateBundleTypeOptions, isLockableProvider}
