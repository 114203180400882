import React, {Component, ReactElement} from 'react'
import {toastr} from 'react-redux-toastr'
import PolyIcon from '../elements/PolyIcon/PolyIcon'

// TODO improve colors on success/error pop ups with a PolyIcon

class Notification extends Component {
  static defaultOptions = {
    timeOut: 5000,
    newestOnTop: false,
    showCloseButton: true,
    position: 'top-center',
    transitionIn: 'fadeIn',
    transitionOut: 'fadeOut',
    progressBar: false,
    attention: false,
    removeOnHover: true,
    removeOnHoverTimeOut: 2000
  }

  /**
   * Each method accepts string or a ReactElement
   * @example Notification.success('Something great just happened')
   * @example Notification.success(<>Something great just happened.<br/>More details here.</>)
   *
   * @param message
   * @param callOptions
   */
  static success = (message: string | ReactElement, callOptions = {}): void => {
    const localOptions: any = {
      icon: <PolyIcon icon="check_circle_outline" size="sm" />
    }
    if (React.isValidElement(message)) {
      localOptions.component = message
      message = ''
    }

    let options = {...(Notification.defaultOptions as any), ...localOptions, ...callOptions}
    toastr.success('', message, options)
  }

  static info = (message: string | ReactElement, callOptions = {}): void => {
    const localOptions: any = {
      icon: <PolyIcon icon="info_outline" size="sm" />
    }
    if (React.isValidElement(message)) {
      localOptions.component = message
      message = ''
    }

    let options = {...(Notification.defaultOptions as any), ...localOptions, ...callOptions}
    toastr.info('', message, options)
  }

  static warning = (message: string | ReactElement, callOptions = {}): void => {
    const localOptions: any = {
      icon: <PolyIcon icon="warning" size="sm" />,
      // prevent auto close
      timeOut: 0
    }
    if (React.isValidElement(message)) {
      localOptions.component = message
      message = ''
    }

    let options = {...(Notification.defaultOptions as any), ...localOptions, ...callOptions}
    toastr.warning('', message, options)
  }

  static error = (message: string | ReactElement, callOptions = {}): void => {
    const localOptions: any = {
      icon: <PolyIcon icon="error" size="sm" />,
      // prevent auto close
      timeOut: 0
    }
    if (React.isValidElement(message)) {
      localOptions.component = message
      message = ''
    }

    let options = {...(Notification.defaultOptions as any), ...localOptions, ...callOptions}
    toastr.error('', message, options)
  }
}

export {Notification}
