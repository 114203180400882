import React from 'react'
import icons from '../../assets/icons/symbol-defs.svg'
import customIcons from '../../assets/icons/symbol-defs-custom.svg'
import {get as _get, isNumber as _isNumber} from 'lodash-es'

interface PolyIconProps {
  /** the name of the icon as defined in symbol-defs.svg file */
  icon?: PolyIconOption
  /** the name of the icon as defined in symbol-defs-custom.svg file */
  customIcon?: PolyCustomIconOption
  /** optional - hover title */
  title?: string
  className?: string
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | number
  onClick?: any
  /** if true, do not set height & width dimensions inline */
  overrideDimensions?: boolean
}

/**
 * Render an icon using the approved Poly icon set.
 *
 * @example <PolyIcon icon="home" title="Home Page" />
 *
 * @help https://onecode.polycom-labs.com/UME/Poly-Icons/tree/master
 * @help https://plcmcdndev.blob.core.windows.net/assets/semantic/latest/examples/plcm/poly-icons.html
 *
 * @param {PolyIconProps} props
 *
 */
const PolyIcon: React.FC<PolyIconProps> = props => {
  const classes = ['poly-icon', props.icon, props.className || ''].join(' ')
  const sizes = {
    xxs: 16,
    xs: 20,
    sm: 24,
    md: 30,
    lg: 36,
    xl: 50
  }

  let height, width
  if (_isNumber(props.size)) {
    height = width = `${props.size}px`
  } else {
    height = width = _get(sizes, props.size as string, 36) + 'px'
  }

  const icon = `${icons}#${props.icon}`
  const customIcon = `${customIcons}#${props.customIcon}`
  const finalIcon = props.icon ? icon : customIcon
  const title = props.title || ''
  const style = props.overrideDimensions ? {} : {height, width}

  return (
    <svg className={classes} xmlnsXlink="http://www.w3.org/1999/xlink" style={style} onClick={props.onClick}>
      <use xlinkHref={finalIcon}>
        <title>{title}</title>
      </use>
    </svg>
  )
}

export default PolyIcon

// typings included at end so code is first visible in file
export type PolyCustomIconOption = string | 'poly_name' | 'poly_mark' | 'landing' | 'table' | 'th' | 'plus'

// delineate icon names for faster/more accurate development
// including string as option for pass-through variables
export type PolyIconOption =
  | string
  | 'propeller'
  | 'VUI_registered'
  | 'VUI_unregistered'
  | 'touch_hand'
  | 'new-window'
  | 'incoming'
  | 'filter'
  | 'table_status_multiple_registration'
  | 'table_status_multiple_registration_expand'
  | 'network-probe'
  | 'offline'
  | 'online'
  | 'archive-outline'
  | 'archive-filled'
  | 'selection_types'
  | 'upgrade'
  | 'dashboard'
  | 'trio-solid'
  | 'trio'
  | 'assets'
  | 'manage_permissions'
  | 'assets1'
  | 'license'
  | 'zt_profiles'
  | 'sites'
  | 'api'
  | 'resource_files'
  | 'profile_deploy'
  | 'manage_devices'
  | 'base_profile'
  | 'firmware'
  | 'claim_device'
  | 'add_device'
  | 'contacts'
  | 'nlp_vui'
  | 'call_outgoing'
  | 'call_missed_alt'
  | 'DND_off'
  | 'DND_on'
  | 'Display_Room'
  | 'HW_Power_DC_PossitivePolarity'
  | 'HW_mini_Din'
  | 'LAN_fill'
  | 'LAN_outline'
  | 'PoE'
  | 'Sign_out'
  | 'USB'
  | 'account_box'
  | 'account_circle'
  | 'active_call'
  | 'add'
  | 'add_box'
  | 'add_circle_fill'
  | 'add_circle_outline'
  | 'add_people'
  | 'admin'
  | 'airplay'
  | 'announcement'
  | 'arrow_back'
  | 'arrow_back_1'
  | 'arrow_close'
  | 'arrow_cursor'
  | 'arrow_down'
  | 'arrow_down_alt'
  | 'arrow_drop_down'
  | 'arrow_drop_up'
  | 'arrow_forward'
  | 'arrow_open'
  | 'arrow_up'
  | 'arrow_up_alt'
  | 'ast_rewind'
  | 'attachment'
  | 'auto_tracking'
  | 'backspace'
  | 'bluetooth'
  | 'bluetooth_connected'
  | 'bluetooth_speaker'
  | 'brightness_high'
  | 'brightness_low'
  | 'bug'
  | 'calendar'
  | 'call'
  | 'call_add'
  | 'call_configuration'
  | 'call_hold'
  | 'call_made'
  | 'call_merge'
  | 'call_missed'
  | 'call_park'
  | 'call_park_outline'
  | 'call_received'
  | 'call_split'
  | 'call_swap'
  | 'call_transfer'
  | 'camera_PTZ'
  | 'camera_control'
  | 'camera_error'
  | 'camera_freeze'
  | 'camera_off'
  | 'camera_on'
  | 'camera_screen_shot'
  | 'camera_tracking_off'
  | 'camera_tracking_on'
  | 'chat'
  | 'check'
  | 'check_box_checked'
  | 'check_circle_fill'
  | 'check_circle_outline'
  | 'ckeck_box_unchecked'
  | 'clear'
  | 'clear_1'
  | 'clear_box'
  | 'clear_circle_fill'
  | 'clear_circle_outline'
  | 'cloud'
  | 'cloud_circle'
  | 'conference_large'
  | 'conference_small'
  | 'configure'
  | 'container_circle'
  | 'content_cable'
  | 'developer'
  | 'developer_sandbox'
  | 'device'
  | 'device_mobile'
  | 'directional_arrow_down_fill'
  | 'directional_arrow_down_outline'
  | 'directional_arrow_left_fill'
  | 'directional_arrow_left_outline'
  | 'directional_arrow_right_fill'
  | 'directional_arrow_right_outline'
  | 'directional_arrow_up_fill'
  | 'directional_arrow_up_outline'
  | 'display'
  | 'download'
  | 'edit'
  | 'edit_copy'
  | 'edit_cut'
  | 'edit_paste'
  | 'eject'
  | 'erase'
  | 'error'
  | 'ethernet'
  | 'expand'
  | 'expand_alt'
  | 'fast_forward'
  | 'favorite'
  | 'file'
  | 'file_text'
  | 'folder'
  | 'folder_open'
  | 'hangup'
  | 'headphones'
  | 'headset'
  | 'help_circle_fill'
  | 'help_circle_outline'
  | 'home'
  | 'input'
  | 'keyboard'
  | 'keyboard_arrow_down'
  | 'keyboard_arrow_left'
  | 'keyboard_arrow_right'
  | 'keyboard_arrow_up'
  | 'keyboard_asterisk'
  | 'keyboard_at'
  | 'keyboard_pound'
  | 'keyboard_return'
  | 'keyboard_space'
  | 'keypad'
  | 'keypad_circle'
  | 'landline'
  | 'language'
  | 'laptop'
  | 'layout'
  | 'layout_gallery'
  | 'link'
  | 'lock'
  | 'mail_fill'
  | 'mail_outline'
  | 'maximize'
  | 'maximize_alt'
  | 'menu'
  | 'mic_off'
  | 'mic_on'
  | 'minimize'
  | 'minimize_alt'
  | 'mirracast'
  | 'modular_room'
  | 'more_horizontal'
  | 'more_vertical'
  | 'network_device'
  | 'info_outline'
  | 'output'
  | 'pairing_off'
  | 'pairing_on'
  | 'pause'
  | 'pause_circle_fill'
  | 'pause_circle_outline'
  | 'people_group'
  | 'person'
  | 'person_add'
  | 'person_pin_circle'
  | 'person_pin_square'
  | 'phone_bluetooth_speaker'
  | 'photo'
  | 'photo_library'
  | 'pin_off'
  | 'pin_on'
  | 'place'
  | 'play'
  | 'play_circle_fill'
  | 'play_circle_outline'
  | 'power_bolt'
  | 'power_on_off'
  | 'presentation_display'
  | 'presentation_play'
  | 'radio_button_off'
  | 'radio_button_on'
  | 'recent_fill'
  | 'recent_outline'
  | 'record_circle_outline'
  | 'redo'
  | 'redo_alt'
  | 'refresh'
  | 'refresh_1'
  | 'remote'
  | 'remove'
  | 'remove_box'
  | 'remove_circle_fill'
  | 'remove_circle_outline'
  | 'replay'
  | 'reply'
  | 'restart'
  | 'restore'
  | 'search'
  | 'security'
  | 'selfview_off'
  | 'selfview_on'
  | 'semicolon'
  | 'send'
  | 'settings'
  | 'share_to'
  | 'sign_in'
  | 'signal_full'
  | 'signal_low'
  | 'signal_medium'
  | 'signal_medium_low'
  | 'signal_off'
  | 'skip_next'
  | 'skip_previous'
  | 'statistics'
  | 'statistics_box'
  | 'stop'
  | 'stop_circle_fil'
  | 'stop_circle_outline'
  | 'trash'
  | 'trash_forever'
  | 'undo'
  | 'undo_alt'
  | 'unlock'
  | 'upload'
  | 'visibility_off'
  | 'visibility_on'
  | 'voicemail'
  | 'volume_max'
  | 'volume_min'
  | 'volume_off'
  | 'warning'
  | 'whiteboard'
  | 'whiteboard_add'
  | 'wifi'
  | 'wifi_disconnected'
  | 'wifisignalstrengthone'
  | 'wifisignalstrengththree'
  | 'wifisignalstrengthtwo'
  | 'zoom_in'
  | 'zoom_out'
  | 'otd'
  | 'cloud_relay'
