import React, {Component} from 'react'
import {connect} from 'react-redux'
import PolyIcon from '../../elements/PolyIcon/PolyIcon'
import {changeMenuVisible, changeProfileVisible} from '../../store/layout/actions'
import './Header.scss'
import {NavLink} from 'react-router-dom'
// import {PolyLogo} from './PolyLogo'
// import logo from '../../assets/vectors/poly-dark-bg.svg'
// import isolatedLogo from '../../assets/vectors/poly-isolated.svg'

interface ComponentProps {
  changeMenuVisible: any
  changeProfileVisible: any
  className?: string
  // conditional because while redux adds it, IDE thinks it should be added
  menuVisible?: boolean
  profileVisible?: boolean
}

class MenuOverlayBase extends Component<ComponentProps, any> {
  toggleMenuVisible = () => {
    this.props.changeMenuVisible(!this.props.menuVisible)
  }

  render() {
    // TODO dev helper
    // props.changeMenuVisible(true)

    // .overlay can have .dark or .light styling
    const overlayClasses = ['overlay', 'from-left']
    if (this.props.menuVisible) {
      overlayClasses.push('show')
    }

    return (
      <nav id="menuOverlay" className={overlayClasses.join(' ')}>
        <div className="menuContainer">
          {/* Padding used to increase onClick area */}
          <div className="text-right pr-1 pt-1 pb-2" onClick={this.toggleMenuVisible}>
            <PolyIcon icon="directional_arrow_left_outline" size="md" />
          </div>
          <ul>
            <li>
              <NavLink exact to="/" title="Home">
                <PolyIcon className="mr-5" icon="home" size="sm" />
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/devices" title="Claim Devices">
                <PolyIcon className="mr-5" icon="device" size="sm" />
                Claim Devices
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    menuVisible: state.layout.menuVisible
  }
}

const MenuOverlay = connect(mapStateToProps, {changeMenuVisible, changeProfileVisible})(MenuOverlayBase)

export {MenuOverlay}
