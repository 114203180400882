import React from 'react'
import {compact as _compact} from 'lodash-es'
import './RadioInput.scss'

const RadioInput = props => {
  return (
    <div className="form-group radio-input">
      <label htmlFor={props.name}>{props.title}</label>
      {props.options.map((option, index) => {
        const id = `${props.id}-${index}`
        const checked = option.value === props.initialValue

        /* TODO call handlechange on initial set up, work in progress below:
        useCallback(() => {
          if (props.initialValue && props.handleChange) {
            props.handleChange({
              target: {
                value: props.initialValue
              }
            })
          }
        }, [])
         */

        const formClassNames = _compact(['form-option', props.optionClassName]).join(' ')

        return (
          <span className={formClassNames} key={index}>
            <input
              type="radio"
              id={id}
              name={props.name}
              value={option.value}
              onChange={props.handleChange}
              defaultChecked={checked}
              disabled={props.disabled}
            />
            <label htmlFor={id}>{option.label}</label>
          </span>
        )
      })}
    </div>
  )
}

export {RadioInput}
