import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link, NavLink} from 'react-router-dom'
import PolyIcon from '../../elements/PolyIcon/PolyIcon'
import {changeMenuVisible, changeProfileVisible} from '../../store/layout/actions'
import './Header.scss'

interface ComponentProps {
  changeMenuVisible: any
  changeProfileVisible: any
  pathname: string
  className?: string
  menuVisible?: boolean
  profileVisible?: boolean
  busy?: boolean
  signedIn?: boolean
}

class HeaderBase extends Component<ComponentProps, any> {
  toggleProfileVisible = () => {
    this.props.changeProfileVisible(!this.props.profileVisible)
  }

  render() {
    const markClasses = ['polymark', 'd-none', 'd-inline-block-md']
    if (this.props.busy) {
      markClasses.push('busy')
    }

    const rightIconClasses = 'mt-1 mr-1'

    return (
      <header className={this.props.className + ' fixed'}>
        <div className="grid">
          <Link to="/" className="col-2 logo ml-5 mt-5">
            <PolyIcon className={markClasses.join(' ')} customIcon="poly_mark" size={40} />
            <PolyIcon className="polyname ml-5 mt-5" customIcon="poly_name" size={40} />
          </Link>
          <nav className="offset-2 col-8 header-nav">
            <ul>
              {/* hide for super small resolutions because home can be emulated by clicking poly name */}
              <li className="d-none d-inline-block-md">
                <NavLink exact to="/" title="Home">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/devices" title="Configure Devices">
                  Configure Devices
                </NavLink>
              </li>
              <li>
                <NavLink to="/api" title="API Access Devices">
                  API Access
                </NavLink>
              </li>
            </ul>
          </nav>
          <span className="offset-10 col-2 text-right">
            {/* minimalism: only show profile icon on landing page */}
            {'/' === this.props.pathname && (
              <PolyIcon className={rightIconClasses} icon="person" onClick={this.toggleProfileVisible} />
            )}
            {'/' !== this.props.pathname && (
              <Link to="/">
                <PolyIcon customIcon="landing" className={rightIconClasses} />
              </Link>
            )}
          </span>
        </div>
      </header>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    menuVisible: state.layout.menuVisible,
    busy: state.layout.busy,
    // also available .search and .hash
    pathname: state.router.location.pathname
  }
}

const Header = connect(mapStateToProps, {changeMenuVisible, changeProfileVisible})(HeaderBase)

export {Header}
