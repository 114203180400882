import {Form, Formik} from 'formik'
import {cloneDeep as _cloneDeep, get as _get, isEqual as _isEqual, set as _set} from 'lodash-es'
import React, {RefObject, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Card} from '../../components/Card/Card'
import {Page} from '../../components/Common/Page'
import {Pagination} from '../../components/Common/Pagination'
import {RadioInput} from '../../components/Form/RadioInput'
import {TextLikeInput} from '../../components/Form/TextLikeInput'
import PolyIcon from '../../elements/PolyIcon/PolyIcon'
import {changeBusy} from '../../store/layout/actions'
import {Nullable} from '../../types/INullable'
import {IParsedData} from '../../types/IParsedData'
import {CSVUtility} from '../../utils/CSVUtility'
import {RemoteData, UIResponse} from '../../utils/RemoteData'
import {Utility} from '../../utils/Utility'
import {ensureEnumValueCaller, ensureValueCaller} from '../../utils/Validation'
import {DropdownList} from 'react-widgets'
import {generateBundleTypeOptions, customBundleTypeOptions, isLockableProvider} from './DeviceBundleTypeOptions'
import './Devices.scss'
import {OptimizelyFeature} from '@optimizely/react-sdk'

interface ComponentProps {
  history: any
}

/**
 * SUCCESSFUL DATA FLOW:
 *
 * User Selects:
 * Configure Devices
 * - OR -
 * Unconfigure Devices
 *
 * User uploads CSV text (values.csv) - OR - uploads a file (values.file)
 *
 * This then triggers Papa.parse to parse the CSV file.
 *
 * Then User Uploads File -> handleUpload -> validateUpload -> initValidateCSV -> validateCSV -> setCSVParsedData
 * User Pastes Text -> handleChange -> initValidateCSV -> validateCSV -> setCSVParsedData
 *
 * initValidateCSV triggers the Papa.parse method with a completion call of validateCSV
 *
 * Looking for a simple CSV example download for the end user?  See history before 5/20/2020 related to 'setSampleCSV' function
 */
const Devices: React.FC<ComponentProps> = props => {
  const dispatch = useDispatch()
  const [remoteResponse, setRemoteResponse] = useState(undefined as Nullable<UIResponse>)
  const [parsedCSVData, setParsedCSVData] = useState(undefined as Nullable<IParsedData>)
  const uploadButton: RefObject<any> = React.createRef()
  const errorDelimiter = '\n<br>'
  const DropdownListAsAny = DropdownList as any

  const defaultPaginationState = {
    items: []
  } as any
  const [pagination, setPagination] = useState(_cloneDeep(defaultPaginationState))

  // Safari can't handle multiline placeholders in textarea, so format differently for Safari
  // The form should work the same and display slightly differently whether Safari is detected or not;
  // that is, do not rely on browser detection one way or the other, it is for a slightly nicer user experience.
  const isSafari = Utility.isSafari()
  // if Safari, placeholder goes before textarea; otherwise goes inside placeholder tag as normal
  const safariCSVPlaceholderText = isSafari ? (
    <div className="placeholder">{CSVUtility.getCsvPlaceholderText()}</div>
  ) : (
    ''
  )
  const csvPlaceholderText = isSafari ? '' : CSVUtility.getCsvPlaceholderText()

  // note a boolean value is converted to string, so just make value string to prevent dev errors
  const isConfiguringOptions = [
    {
      label: (
        <span className="button-list-text-sm">
          Configure
          <br />
          Devices
        </span>
      ),
      value: 'true'
    },
    {
      label: (
        <span className="button-list-text-sm">
          Unconfigure
          <br />
          Devices
        </span>
      ),
      value: 'false'
    }
  ]

  // note a boolean value is converted to string, so just make value string to prevent dev errors
  const isProviderLockedOptions = [
    {
      label: (
        <span className="button-list-text-sm">
          Keep
          <br />
          Unlocked
        </span>
      ),
      value: 'false'
    },
    {
      label: <span className="button-list-text-sm">Lock</span>,
      value: 'true'
    }
  ]

  const dispatchBusy = (isBusy: boolean) => {
    dispatch(changeBusy(isBusy))
  }

  // clear file upload after data parsed
  const resetUploadButton = () => {
    // this makes it easier for the user in the unlikely case user alternates
    // between uploading CSV, inputting data then uploading CSV again
    _set(uploadButton, 'current.value', null)
  }

  const handleUpload = (bundle: string | undefined, setFieldValue: any, setErrors: any) => {
    return (e: React.SyntheticEvent) => {
      const reader = new FileReader()
      let file = _get(e, ['target', 'files', 0])

      const setCSVErrors = (errors_array: string[]) => {
        if (errors_array.length) {
          setErrors({csv: errors_array.join(errorDelimiter)})
        }
      }

      const setCSVFieldValue = (csv: string) => {
        setFieldValue('csv', csv)
      }

      if (file) {
        reader.readAsDataURL(file)
        reader.onload = () => {
          setFieldValue('csvFile', true)

          CSVUtility.validateUpload(file, reader.result as any, bundle, setCSVFieldValue, setCSVErrors, dispatchBusy)
            .then(handleParsedResponse)
            .then(resetUploadButton)
        }
      }
    }
  }

  const reprocessUpload = (values: any, bundle: string | undefined) => {
    // NOTE errors should be caught on first CSV processing and will not change when reprocessing CSV
    // so setErrors is a no-op in this case (keep errors as-is)
    CSVUtility.initValidateCSV(values.csv, bundle, () => {}, dispatchBusy).then(handleParsedResponse)
  }

  /**
   * Process IParsedData response from CSVUtility.validateUpload or CSVUtility.initValidateCSV
   */
  const handleParsedResponse = parsed => {
    // only update values and reset pagination if parsed data has changed
    if (!_isEqual(parsed, parsedCSVData)) {
      // when data changes, start pagination from a basic / initial state, resetting currentItems/currentPage/totalPages etc
      const state = {...defaultPaginationState, items: _get(parsed, 'data')}
      setPagination(state)
      setParsedCSVData(parsed as Nullable<IParsedData>)

      // reset results stage
      setRemoteResponse(undefined)
    }
  }

  /**
   * Validate & parse CSV
   * @param csv
   * @param bundle
   * @param errors
   */
  const validateCSV = (csv: string, bundle: string | undefined, errors: any) => {
    const setCSVErrors = (errors_array: string[]) => {
      if (errors_array.length) {
        errors.csv = errors_array.join(errorDelimiter)
      }
    }

    // NOTE CSV field *can* be empty in the case of a file upload
    CSVUtility.initValidateCSV(csv, bundle, setCSVErrors, dispatchBusy).then(handleParsedResponse)
  }

  /**
   * @param isConfiguring - note this is nullable for typing, but validation will ensure it is a boolean
   */
  const submit = (isConfiguring: boolean) => {
    // method is 'patch' if configuring devices, or 'delete' if unconfiguring devices
    const method = isConfiguring ? 'patch' : 'delete'
    const noun = 'device'
    const verb = isConfiguring ? 'configure' : 'unconfigure'

    // at this point, IParsedData has been vetted and is not Nullable
    RemoteData.call('/devices', method, (parsedCSVData as IParsedData).combined, setRemoteResponse, noun, verb)

    // debug helper
    // console.log('FINAL RESULT:', method, (parsedCSVData as IParsedData).combined)
  }

  /**
   * Validates form fields, and kicks off CSV textarea parsing if necessary.
   * Note CSV upload parsing is kicked off by the handleUpload function.
   */
  const validate = (values: any) => {
    return new Promise(async (resolve: any, reject: any) => {
      let errors: any = {}
      const ensureValue = ensureValueCaller('Please enter a value', values, errors)
      const ensureTrueFalseStringValue = ensureEnumValueCaller(
        'Please enter a value',
        ['true', 'false'],
        values,
        errors
      )

      ensureValue('isConfiguring')
      ensureTrueFalseStringValue('isConfiguring')

      // trigger additional effect parsing
      // NOTE useEffect cannot just be used inside <Formik> element because it is itself a hook
      if (values.csv && 0 === Object.keys(errors).length) {
        // CSV textarea entered, clear out raw csvFile data
        values.csvFile = false
        const bundle = getBundle(values)
        // no errors, push CSV data
        await validateCSV(values.csv, bundle, errors)
      } else if (!values.csv && !values.csvFile) {
        // clear parsed data
        setParsedCSVData(undefined)
      } else if (values.csvFile) {
        // recompute, possible that bundle changed
        reprocessUpload(values, getBundle(values))
      }

      if ('true' === values.isConfiguring && !values.bundle) {
        errors.bundle = 'Select a bundle provider'
      }

      if ('custom' === values.bundle) {
        if (!values.customBundle) {
          errors.customBundle = 'Select a custom bundle identifier'
        }
      }

      resolve(errors)
    })
  }

  const getBundle = (values: any) => {
    // if no bundle set OR user is unconfiguring devices, don't return any bundle
    if (!values.bundle || 'false' === values.isConfiguring) {
      return undefined
    }

    let bundle = 'custom' === values.bundle ? values.customBundle : values.bundle

    // append '_locked' to bundle type if locked
    if ('true' === values.isProviderLocked) {
      bundle += '_locked'
    }

    return bundle
  }

  type booleanString = 'true' | 'false'

  return (
    <Page title="Configure Devices">
      <Card className="devices col-12 auto left-col-sticky" contentClasses="p-1">
        <Formik
          initialValues={{
            csv: '',
            // boolean tracking if csv field is based off a file
            csvFile: false,
            isConfiguring: undefined as Nullable<booleanString>,
            isProviderLocked: 'false' as booleanString,
            parsedData: undefined,
            bundle: undefined,
            // if simple bundle options (Zoom, 8x8 etc) not sufficient, allow user to choose a custom bundle
            customBundle: undefined
          }}
          onSubmit={async values => {
            submit('true' === values.isConfiguring)
          }}
          validate={validate}
        >
          {formikProps => {
            // other available formikProps:
            // @help https://jaredpalmer.com/formik/docs/api/formik#props-1
            const {
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue,
              setErrors
            } = formikProps
            const hasErrors = !!Object.keys(errors).length

            const isConfiguring = 'true' === values.isConfiguring
            const isUnconfiguring = 'false' === values.isConfiguring
            const readyToChooseBundle = undefined !== values.isConfiguring && isConfiguring
            const bundleChosen = values.bundle && !errors.bundle && !errors.customBundle
            const readyToUploadData = bundleChosen || isUnconfiguring
            const readyForDataReview = (hasErrors || parsedCSVData) && (bundleChosen || isUnconfiguring)
            const readyToSubmit = readyForDataReview && !hasErrors && parsedCSVData

            return (
              <Form onSubmit={handleSubmit}>
                <ol className="circle">
                  <li className="button-list">
                    <h2>What would you like to do?</h2>
                    {/* NOTE this step's options lock once CSV data is uploaded to prevent complicated re-parsing steps on change */}
                    <RadioInput
                      id="isConfiguring"
                      name="isConfiguring"
                      options={isConfiguringOptions}
                      initialValue={values.isConfiguring}
                      handleChange={handleChange}
                    />
                  </li>
                  {readyToChooseBundle && (
                    <li className="button-list">
                      <h2>Great! Which provider should we use to configure your devices?</h2>
                      <OptimizelyFeature feature="mono_partner_available_providers">
                        {(isEnabled: boolean, variables: any) => {
                          const bundleTypeOptions = generateBundleTypeOptions(isEnabled, variables)

                          return (
                            <RadioInput
                              id="bundle"
                              name="bundle"
                              options={bundleTypeOptions}
                              optionClassName="bundle"
                              initialValue={values.bundle}
                              handleChange={handleChange}
                            />
                          )
                        }}
                      </OptimizelyFeature>
                      {'custom' === values.bundle && (
                        <DropdownListAsAny
                          filter="contains"
                          name="customBundle"
                          id="customBundle"
                          searchIcon={<PolyIcon icon="search" size={18} />}
                          data={customBundleTypeOptions}
                          textField="label"
                          valueField="value"
                          onChange={obj => {
                            setFieldValue('customBundle', obj.value)
                          }}
                        />
                      )}
                      {errors.bundle && touched.bundle && <div className="warning notice-sm">{errors.bundle}</div>}
                      {errors.customBundle && touched.customBundle && (
                        <div className="warning notice-sm">{errors.customBundle}</div>
                      )}
                    </li>
                  )}
                  {/* Once a provider is chosen, if it is a lockable provider, ask user if they want it locked. */}
                  {readyToUploadData && (
                    <OptimizelyFeature feature="mono_partner_lockable_providers">
                      {(isEnabled: boolean, variables: any) => {
                        const isLockable = isLockableProvider(isEnabled, variables, values.bundle)

                        if ('false' === values.isConfiguring || !isLockable) {
                          // could be switching from Zoom (a lockable provider) to a non-lockable provider,
                          // so ensure isProviderLocked is false
                          if ('true' === values.isProviderLocked) {
                            // setTimeout mitigates a rare issue where this could take precedent over another setFieldValue
                            // for example, without setTimeout, select Configure Devices -> Zoom -> Lock -> then
                            // insert arbitrary serial number. then select Poly in Step 2, bundle is "zoom" not "poly_video"
                            setTimeout(() => {
                              setFieldValue('isProviderLocked', 'false')
                            }, 0)
                          }

                          return <></>
                        }

                        return (
                          <li className="button-list">
                            <h2>
                              Would you like to lock the configuration to {Utility.providerReadable(values.bundle)}?
                            </h2>
                            <RadioInput
                              id="isProviderLocked"
                              name="isProviderLocked"
                              options={isProviderLockedOptions}
                              initialValue={values.isProviderLocked}
                              handleChange={handleChange}
                            />
                            <p className="info">
                              <PolyIcon className="mr-25" icon="info_outline" size={16} />
                              Locking a device prevents the customer from reconfiguring it to another provider. A device
                              should only be locked if the order restricts the device to a single provider.
                            </p>
                          </li>
                        )
                      }}
                    </OptimizelyFeature>
                  )}
                  {readyToUploadData && (
                    <li>
                      <h2>Paste your list of serial numbers</h2>
                      <div className="text-inline inline-lg mb-25">
                        <PolyIcon className="mr-5" icon="edit_paste" size={16} />
                        Paste spreadsheet, CSV or plain text data. Alternatively, you can
                        {/* note Formik does not support file uploads, so handled differently */}
                        <input
                          hidden
                          accept=".csv"
                          name="import"
                          type="file"
                          ref={uploadButton}
                          onChange={handleUpload(getBundle(values), setFieldValue, setErrors)}
                        />
                        <a
                          href="#na"
                          className="ml-25"
                          onClick={e => {
                            e.preventDefault()
                            uploadButton.current.click()
                          }}
                        >
                          upload a CSV file
                        </a>
                        .
                      </div>
                      {safariCSVPlaceholderText}
                      {/* if not Safari browser, placeholder below will be filled in properly */}
                      <TextLikeInput
                        name="csv"
                        autoFocus
                        className="rounded csv"
                        cols={80}
                        value={values.csv}
                        placeholder={csvPlaceholderText}
                        onBlur={handleBlur}
                        rows={8}
                        handleChange={handleChange}
                      />
                    </li>
                  )}
                  {/*
                  <li>
                      <pre>
                      Touched:
                      <br />
                        {JSON.stringify(touched, null, 2)}
                        <br />
                      Values:
                      <br />
                        {JSON.stringify(values, null, 2)}
                        <br />
                      Errors:
                      <br />
                        {JSON.stringify(errors, null, 2)}
                      </pre>
                  </li> */}
                  {readyForDataReview && (
                    <li>
                      {parsedCSVData && (
                        <h2>
                          Review Data - {parsedCSVData.data.length} Device{parsedCSVData.data.length > 1 ? 's' : ''}
                        </h2>
                      )}
                      {hasErrors && errors.csv && (
                        <>
                          <h3>Errors found</h3>
                          {errors.csv.split(errorDelimiter).map((error: string, i: number) => {
                            return (
                              <div className="error-inline inline-lg" key={i}>
                                <PolyIcon icon="warning" size={16} className="mr-25" />
                                {error}
                              </div>
                            )
                          })}
                        </>
                      )}
                      {!hasErrors && parsedCSVData && (
                        <div className="parsed">
                          <div className="help-inline inline-lg mb-1em mt-5em">
                            <PolyIcon className="mr-5" icon="help_circle_outline" size={16} />
                            Data has<strong> not </strong>been submitted yet. Please review then submit or upload again.
                          </div>
                          <table className="table rounded border-collapse">
                            <thead>
                              <tr>
                                {parsedCSVData.fields &&
                                  parsedCSVData.fields.map((field: string, i: number) => <th key={i}>{field}</th>)}
                              </tr>
                            </thead>
                            <tbody>
                              {pagination.currentItems &&
                                pagination.currentItems.map((row: string[], i: number) => {
                                  return (
                                    <tr key={i}>
                                      {row.map((field: string, j: number) => (
                                        <td key={j}>{field}</td>
                                      ))}
                                    </tr>
                                  )
                                })}
                            </tbody>
                          </table>
                          {pagination.currentPage && pagination.totalPages > 1 && (
                            <div className="pagination-summary">
                              Page <span>{pagination.currentPage}</span> of <span>{pagination.totalPages}</span>
                            </div>
                          )}
                          {pagination.items && pagination.items.length > 0 && (
                            <Pagination
                              totalRecords={pagination.items.length}
                              refreshWatch={pagination.items}
                              pageLimit={10}
                              pageNeighbors={1}
                              onPageChanged={Utility.pageChangeHandler(pagination, setPagination)}
                            />
                          )}
                        </div>
                      )}
                    </li>
                  )}
                  {readyToSubmit && (
                    <li>
                      <h2>Finalize</h2>
                      <button className="button button-md" type="submit">
                        <PolyIcon className="mr-5" icon="upload" size={18} />
                        Submit Request
                      </button>
                    </li>
                  )}
                  {remoteResponse && (remoteResponse.errors || remoteResponse.successes) && (
                    <li>
                      <h2>Results</h2>
                      {remoteResponse.successes && (
                        <div className="success">
                          {remoteResponse.successes.map((message, i) => (
                            <div key={i}>{message}</div>
                          ))}
                        </div>
                      )}
                      {remoteResponse.errors && (
                        <div className="warning">
                          {remoteResponse.errors.map((message, i) => (
                            <div key={i}>{message}</div>
                          ))}
                        </div>
                      )}
                    </li>
                  )}
                </ol>
                {/* DEBUG helper
                <pre>
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre>
                  {JSON.stringify(pagination, null, 2)}
                </pre>
                <pre>
                  {JSON.stringify(parsedCSVData, null, 2)}
                </pre>
                */}
              </Form>
            )
          }}
        </Formik>
      </Card>
    </Page>
  )
}

export {Devices}
