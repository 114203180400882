import {
  every as _every,
  forEach as _forEach,
  get as _get,
  has as _has,
  isObject as _isObject,
  isString as _isString,
  map as _map,
  size as _size,
  values as _values,
  zipObject as _zipObject
} from 'lodash-es'
import {Component} from 'react'
import Papa from 'papaparse'
import {Nullable} from '../types/INullable'
import {IParsedData} from '../types/IParsedData'

class CSVUtility extends Component {
  static exampleRow = ['SAMPLE123ABCD']
  static exampleRow2 = ['SAMPLE456ABCD']

  /**
   * Given a CSV string, validate it is properly formatted.
   * This processing is centralized for uploads and pasted text.
   */
  static initValidateCSV = async (csv: string, bundle: string | undefined, setErrors: any, dispatchBusy: any) => {
    dispatchBusy(true)

    // remove byte order mark if it exists, otherwise first row may start with "ï»¿"
    if (csv.length >= 3 && csv.substr(0, 3) === String.fromCharCode(0xef, 0xbb, 0xbf)) {
      csv = csv.substr(3)
    }

    return new Promise((resolve, reject) => {
      // TODO determine if header exists, or determine after
      const options = {
        // header: ('header' === form.uploadType),
        // NOTE: do not use worker: true until https://github.com/webpack/webpack/issues/6525 resolved.
        // produces `window is not defined` due to lack of shared webpack bundles for web worker
        // worker: true,
        skipEmptyLines: true,
        complete: (raw_results: any) => {
          const parsedData = CSVUtility.validateCSV(csv, raw_results, bundle, setErrors, dispatchBusy)

          // above call is synchronous, so call resolve as soon as it is finished
          resolve(parsedData)
        },
        // assume comma delimiter
        delimiter: ','
      } as any

      Papa.parse(csv, options)
    })
  }

  /**
   * Does not return a result because a result is unnecessary, and due to asynchronous nature
   * of validateCSV.
   *
   * @param file
   * @param input - expecting a base-64 encoded string such as `data:text/csv;base64,bmf...Cws`
   * @param bundle
   * @param setCSVFieldValue
   * @param setErrors
   * @param dispatchBusy
   */
  static validateUpload = async (
    file: any,
    input: string | ArrayBuffer,
    bundle: string | undefined,
    setCSVFieldValue: any,
    setErrors: any,
    dispatchBusy: any
  ): Promise<Nullable<IParsedData>> => {
    return new Promise((resolve, reject) => {
      // accessible: file.name, file.lastModified, file.lastModifiedDate, file.size, file.type

      if (!file || !_isObject(file)) {
        setErrors(['Did not receive uploaded data.  Please try again.'])
        setCSVFieldValue('')

        resolve()
      }

      // NOTE Windows 10/Chrome 72 may have an empty type when uploaded.  Thus, it is not beneficial
      // to check type is 'text/csv' as results may vary by browser.
      if (!_isString(input)) {
        setErrors(['Received an empty file.  Please ensure file has data and try uploading again.'])
        setCSVFieldValue('')

        resolve()
      }

      // parse string; may be text/csv (typical for Mac) or application/octet-stream (typical for Windows)
      const base64 = (input as any).replace(/data:([^;]+);base64,/, '')

      if (!/^[A-Za-z\d+/=]+$/.test(base64)) {
        setErrors(['Malformed data.  Please try again.'])
        setCSVFieldValue('')

        resolve()
      }

      let csv
      try {
        csv = window.atob(base64)
      } catch (err) {
        console.error(err)
        setErrors(['Unable to retrieve data.  Please try again.'])
        setCSVFieldValue('')

        resolve()
      }

      setErrors([])
      setCSVFieldValue(csv)

      CSVUtility.initValidateCSV(csv, bundle, setErrors, dispatchBusy).then(parsed =>
        resolve(parsed as Nullable<IParsedData>)
      )
    })
  }

  static getCsvUrl = (csv: string) => {
    const blob = new Blob([csv], {type: 'text/csv'})

    return window.URL.createObjectURL(blob)
  }

  static getCsvPlaceholderText = () => {
    return `${CSVUtility.exampleRow[0]}
${CSVUtility.exampleRow2[0]}`
  }

  static getSampleCsv = () => {
    const newline = '\r\n'

    return CSVUtility.exampleRow[0] + newline + CSVUtility.exampleRow2[0]
  }

  static validateCSV = (
    text: string,
    raw_results: any,
    bundle: string | undefined,
    setErrors: any,
    dispatchBusy: any
  ): Nullable<IParsedData> => {
    // errors include column mismatches (e.g. header is 7 columns, and a row is 9 columns)
    if (raw_results && _size(raw_results.errors) > 0) {
      console.error(raw_results)
      const localErrors: string[] = []
      _forEach(raw_results.errors, (error: any) => {
        if (_has(error, 'row') && 'undefined' !== typeof error.row) {
          // row 0 indicates first data row, which opening a CSV would be indicated as row 2
          const error_row_adjusted = error.row + 2
          localErrors.push(`${error.message} at row ${error_row_adjusted}\n`)
        } else {
          localErrors.push(`${error.message}\n`)
        }
      })

      setErrors(localErrors)

      dispatchBusy(false)

      return undefined
    }

    // change objects {name: "mydevice", ...} into value array ["mydevice", ...]
    let data = _map(raw_results.data, row => {
      return _values(row)
    })

    // since pasted data may be tab-separated (TSV), create CSV text from retrieved data
    let fields = ['Serial Number']
    if (bundle) {
      fields.push('Bundle')
    }

    // user should just upload one column of data
    if (1 === data.length) {
      // handle case where data is all on one row e.g. "a, b, c" CSV

      if (bundle) {
        // transform data from [['a', 'b']] => [['a', 'bundle-id'], ['b', 'bundle-id']]
        data = _map(data[0], datum => {
          return [datum, bundle]
        })
      } else {
        // transform data from [['a', 'b']] => [['a'], ['b']]
        data = _map(data[0], datum => {
          return [datum]
        })
      }
    } else if (data.length > 1) {
      // handle case where data is all in one column
      // NOTE data does not need to be transformed, [['a'], ['b']] is correct

      // ensure every array have only one element (column size of 1)
      const isValid = _every(data, row => 1 === row.length)

      if (!isValid) {
        setErrors(['Ensure only one entry per line.'])

        dispatchBusy(false)

        return undefined
      }

      if (bundle) {
        data = _map(data, datum => {
          return [...datum, bundle]
        })
      }
    }

    let firstValue = _get(data, [0, 0], '')
    // if user uploads serial* as first data item, consider it a header and remove it from value
    if (/serial.*/i.test(firstValue)) {
      // remove first element of array
      data.shift()
    }

    // loose search to transform field names to API friendly header names
    fields = _map(fields, (field: string) => {
      field = field.replace(/serial.*/i, 'serial')
      field = field.replace(/bundle.*/i, 'bundle')

      return field
    })

    const csv = Papa.unparse({fields, data})
    dispatchBusy(false)
    setErrors([])

    // parsed is final object; parsed.csv can be submitted to remote API
    // note raw_results is now out of date because of serial-only parsing
    // NOTE could use Object.fromEntries, but Edge 18 doesn't comply as of Nov 2019.. you did it again, Microsoft!
    const combined = _map(data, row => {
      return _zipObject(fields, row)
    })

    return {csv, fields, data, combined}
  }
}

export {CSVUtility}
