const ensureValueCaller = (message: string, values: any, errors: any) => (field: string) => {
  if (!values[field] || '' === values[field]) {
    errors[field] = message
  }
}

const ensureBooleanCaller = (message: string, values: any, errors: any) => (field: string) => {
  if (![true, false].includes(values[field])) {
    if (!values[field] || '' === values[field]) {
      errors[field] = message
    }
  }
}

const ensureEnumValueCaller = (message: string, enumValues: any[], values: any, errors: any) => (field: string) => {
  if (!enumValues.includes(values[field])) {
    if (!values[field] || '' === values[field]) {
      errors[field] = message
    }
  }
}

export {ensureBooleanCaller, ensureEnumValueCaller, ensureValueCaller}
