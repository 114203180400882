import React from 'react'
import {Auth} from 'aws-amplify'
import PolyIcon from '../../elements/PolyIcon/PolyIcon'
import Countdown, {zeroPad} from 'react-countdown'
import './SignInPane.scss'

const SignInPane: React.FC<{}> = props => (
  <div className="signin">
    <h1 className="title">Sign In</h1>
    <SSOButtons />
    <div className="divider">OR</div>
    <Passwordless />
  </div>
)

const SSOButtons: React.FC<{}> = props => (
  <>
    <span className="section">Corporate credentials:</span>
    <button className="button" onClick={() => Auth.federatedSignIn({customProvider: 'Microsoft'})}>
      Sign in with SSO
    </button>
    {/* <button className="button" onClick={() => Auth.federatedSignIn({customProvider: 'GoogleSuite'})}>
      Google Suite
    </button> */}
  </>
)

const Passwordless: React.FC<{}> = props => {
  const [cognitoUser, setCognitoUser] = React.useState<any>(null)
  const [error, setError] = React.useState('')

  const handleEmailInputSubmit = async (inputEmail: string) => {
    if (!inputEmail || typeof inputEmail !== 'string') return setError('Please provide a valid email address.')

    // sanitize email input
    const lowercaseEmail = inputEmail.toLowerCase()
    const simpleEmailRegex = /^\S+@\S+$/
    if (!simpleEmailRegex.test(lowercaseEmail)) return setError('Not a valid email address.')

    // verify this is an active user
    try {
      const user = await Auth.signIn(lowercaseEmail)
      setError('')
      setCognitoUser(user)
    } catch (error) {
      if (error.code === 'NotAuthorizedException') {
        setError('This account has not been granted access.')
      } else {
        console.error('Error while loading user.', error)
      }
    }
  }

  const handleCodeInputCancel = () => {
    setError('')
    setCognitoUser(null)
  }

  const handleCodeInputSubmit = async inputCode => {
    try {
      const result = await Auth.sendCustomChallengeAnswer(cognitoUser, inputCode)
      if (!result.signInUserSession) setError('Invalid code. Please try again.')
    } catch (error) {
      if (error.name === 'NotAuthorizedException') {
        setError('Too many invalid code attempts. Please double check your email and try again.')
        setCognitoUser(null)
      } else {
        setError(error.message)
      }
    }
  }

  return (
    <>
      <PasswordlessError message={error} />
      {!cognitoUser ? <EmailInput onSubmit={handleEmailInputSubmit} /> : <CodeInput email={cognitoUser.username} onCancel={handleCodeInputCancel} onSubmit={handleCodeInputSubmit} />}
    </>
  )
}

const PasswordlessError: React.FC<{message?: string}> = props => (
  <>
    {props.message && (
      <div className="passwordless-error">
        <PolyIcon icon="warning" size={30} />
        <div className="passwordless-error-message">{props.message}</div>
      </div>
    )}
  </>
)

const EmailInput: React.FC<{onSubmit: (email: string) => void}> = props => {
  const [email, setEmail] = React.useState('')

  return (
    <>
      <span className="section">Enter your email address:</span>
      <div className="passwordless-form">
        <div className="field">
          <PolyIcon className="label" icon="mail_fill" />
          <input className="input" name="email" type="email" placeholder="email" maxLength={50} value={email} onKeyPress={event => event.key === 'Enter' && props.onSubmit(email)} onChange={event => setEmail(event.target.value)} />
          <PolyIcon className={email ? 'submit' : 'submit disabled'} icon="directional_arrow_right_outline" size={55} onClick={() => props.onSubmit(email)} />
        </div>
      </div>
    </>
  )
}

const CodeInput: React.FC<{
  email: string
  onSubmit: (code: string) => void
  onCancel: () => void
  countdownMillis?: number
}> = props => {
  const [hasTime, setHasTime] = React.useState(true)
  const [code, setCode] = React.useState('')
  const [countdown] = React.useState(Date.now() + (props.countdownMillis || 150000))

  const renderCountdown = ({minutes, seconds}) => <span>{hasTime ? `Code expires in ${zeroPad(minutes, 1)}:${zeroPad(seconds)}.` : 'Code expired.'}</span>

  return (
    <>
      <div className="passwordless-form">
        <p className="sub">
          A verification code has been emailed to <b>{props.email}</b>.
        </p>
        <p className="sub">Please enter it below to verify your identity and sign in.</p>
        <div className="field">
          <PolyIcon className="label" icon="keypad_circle" />
          <input className="input" name="code" type="text" placeholder="code" disabled={!hasTime} maxLength={10} value={code} onKeyPress={event => event.key === 'Enter' && props.onSubmit(code)} onChange={event => setCode(event.target.value)} />
          <PolyIcon className={hasTime && code ? 'submit' : 'submit disabled'} icon="directional_arrow_right_outline" size={50} onClick={() => props.onSubmit && props.onSubmit(code)} />
        </div>
        <span className="sub">
          <Countdown renderer={renderCountdown} date={countdown} onComplete={() => setHasTime(false)} />
          <button className="cancel-button" onClick={() => props.onCancel && props.onCancel()}>
            Cancel
          </button>
        </span>
      </div>
    </>
  )
}

export {SignInPane}
