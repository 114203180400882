import React from 'react'
import {connect} from 'react-redux'
import background from '../../assets/images/rapids-md.jpg'
import {Header} from '../Header/Header'
import {MenuOverlay} from '../Header/MenuOverlay'
import {ProfileOverlay} from '../Header/ProfileOverlay'
import './Layout.scss'

interface LayoutProps {
  signedIn?: boolean
  logoOnly?: boolean
  profileVisible?: boolean
  menuVisible?: boolean
  children?: any
}

const LayoutBase: React.FC<LayoutProps> = props => {
  let layoutClasses: string[] = ['app-root']

  if (props.signedIn) {
    layoutClasses.push('signedIn')
    layoutClasses.push('dark')
  } else {
    layoutClasses.push('signedOut')
    layoutClasses.push('light')
  }
  if (props.profileVisible) {
    layoutClasses.push('profile-overlay')
  }
  if (props.menuVisible) {
    layoutClasses.push('menu-overlay')
  }

  return (
    <>
      {props.signedIn ? (
        // .app-root head
        <div className={layoutClasses.join(' ')}>
          <div className="grid">
            <Header className="col-12" signedIn={props.signedIn} />
            <main className="content-container col-12">{props.children}</main>
            {/* If re-enabling footer, check for overlay on pages with content that requires scrolling down */}
            {/*<Footer className="col-12"/>*/}
          </div>
          {/* Keep overlays out of grid */}
          <MenuOverlay />
          <ProfileOverlay />
        </div>
      ) : (
        // note main for the login page does not use the normal grid
        // .app-root head
        <div className={layoutClasses.join(' ')} style={{backgroundImage: `url(${background})`}}>
          <main>{props.children}</main>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    profileVisible: state.layout.profileVisible,
    menuVisible: state.layout.menuVisible
  }
}

const Layout = connect(mapStateToProps)(LayoutBase)

export {Layout}
