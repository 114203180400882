import React, {Component} from 'react'
import {debounce as _debounce, range as _range} from 'lodash-es'
import './Pagination.scss'

const LEFT_PAGE = 'LEFT'
const RIGHT_PAGE = 'RIGHT'

// minor variation to include last number
const range = (start, stop) => {
  return _range(start, stop + 1)
}

interface ComponentProps {
  totalRecords: number
  pageLimit?: number
  pageNeighbors?: 0 | 1 | 2
  onPageChanged?: any
  refreshWatch: any
}

class Pagination extends Component<ComponentProps> {
  pageLimit
  totalRecords
  pageNeighbors
  totalPages
  state
  debounceInitialize

  constructor(props) {
    super(props)
    const {pageLimit = 30, pageNeighbors = 0} = props

    this.pageLimit = pageLimit

    // pageNeighbors can be: 0, 1 or 2
    this.pageNeighbors = Math.max(0, Math.min(pageNeighbors, 2))
    this.state = {currentPage: 1}
    this.debounceInitialize = _debounce(() => {
      this.initialize()
    }, 500)
  }

  initialize() {
    const {totalRecords = null} = this.props
    this.totalRecords = totalRecords

    this.totalPages = Math.ceil(this.totalRecords / this.pageLimit)

    // setState will only work after component mounted
    this.setState({currentPage: 1})

    this.gotoPage(1)
  }

  // respond to changes in uploaded data by re-initializing pagination controls
  componentDidUpdate(prevProps) {
    if (this.props.refreshWatch !== prevProps.refreshWatch) {
      // re-initialize pagination with a debounced function

      // NOTE if user types in data into 'paste data directly' textarea,
      // debounceInitialize will prevent this error:
      // "Uncaught Error: Maximum update depth exceeded. This can happen when a component
      // repeatedly calls setState inside componentWillUpdate or componentDidUpdate.
      // React limits the number of nested updates to prevent infinite loops."
      this.debounceInitialize()
    }
  }

  componentDidMount() {
    this.initialize()
  }

  /**
   * Without this, debounceInitialize may trigger when this component is unmounted.
   *
   * "Warning: Can't perform a React state update on an unmounted component.
   * This is a no-op, but it indicates a memory leak in your application.
   * To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method."
   */
  componentWillUnmount(): void {
    // cancel any debounced functions that would call
    this.debounceInitialize.cancel()
  }

  gotoPage = page => {
    const {onPageChanged = f => f} = this.props

    const currentPage = Math.max(0, Math.min(page, this.totalPages))

    const paginationData = {
      currentPage,
      totalPages: this.totalPages,
      pageLimit: this.pageLimit,
      totalRecords: this.totalRecords
    }

    this.setState({currentPage}, () => onPageChanged(paginationData))
  }

  handleClick = page => evt => {
    evt.preventDefault()
    this.gotoPage(page)
  }

  handleMoveLeft = evt => {
    evt.preventDefault()
    this.gotoPage(this.state.currentPage - this.pageNeighbors * 2 - 1)
  }

  handleMoveRight = evt => {
    evt.preventDefault()
    this.gotoPage(this.state.currentPage + this.pageNeighbors * 2 + 1)
  }

  /**
   * Let's say we have 10 pages and we set pageNeighbors to 2
   * Given that the current page is 6
   * The pagination control will look like the following:
   *
   * (1) < {4 5} [6] {7 8} > (10)
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbors
   */
  fetchPageNumbers = () => {
    const totalPages = this.totalPages
    const currentPage = this.state.currentPage
    const pageNeighbors = this.pageNeighbors

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = this.pageNeighbors * 2 + 3
    const totalBlocks = totalNumbers + 2

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbors)
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbors)

      let pages: any = range(startPage, endPage)

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2
      const hasRightSpill = totalPages - endPage > 1
      const spillOffset = totalNumbers - (pages.length + 1)

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1)
          pages = [LEFT_PAGE, ...extraPages, ...pages]
          break
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset)
          pages = [...pages, ...extraPages, RIGHT_PAGE]
          break
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE]
          break
        }
      }

      return [1, ...pages, totalPages]
    }

    return range(1, totalPages)
  }

  render() {
    if (!this.totalRecords || this.totalPages === 1) return null

    const {currentPage} = this.state
    const pages = this.fetchPageNumbers()

    return (
      <ul className="pagination">
        {pages.map((page, index) => {
          const buttonClasses = 'page-link button button-sm'

          if (page === LEFT_PAGE)
            return (
              <li key={index} className="page-item">
                <button className={buttonClasses} aria-label="Previous" onClick={this.handleMoveLeft}>
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </button>
              </li>
            )

          if (page === RIGHT_PAGE)
            return (
              <li key={index} className="page-item">
                <button className={buttonClasses} aria-label="Next" onClick={this.handleMoveRight}>
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only">Next</span>
                </button>
              </li>
            )

          return (
            <li key={index} className={`page-item${currentPage === page ? ' active' : ''}`}>
              <button className={buttonClasses} onClick={this.handleClick(page)}>
                {page}
              </button>
            </li>
          )
        })}
      </ul>
    )
  }
}

export {Pagination}
