import React, {useState, useEffect} from 'react'
import {CopyToClipboard} from '../../components/Common/CopyToClipboard'
import {Page} from '../../components/Common/Page'
import {Card} from '../../components/Card/Card'
import {Utility} from '../../utils/Utility'

interface ComponentProps {}

const API: React.FC<ComponentProps> = () => {
  const [keyVisible, setKeyVisible] = useState(false)
  const key = window.localStorage.getItem('apiKey') || ''
  const apiDocsUrl = Utility.envVariable('REACT_APP_PARTNER_API_DOCS', false, 'https://zto.poly.com/api/docs/index.html')
  const keyElement = (
    <span>
      {!keyVisible && (
        <>
          <strong>********</strong>
          <button
            className="button-text-only ml-25"
            onClick={() => {
              setKeyVisible(true)
            }}
          >
            Show
          </button>
        </>
      )}
      {keyVisible && (
        <>
          <strong id="api-key" className="mr-5">
            {key}
          </strong>
          <CopyToClipboard value={key} />
          <button className="button-text-only ml-25" onClick={() => setKeyVisible(false)}>
            Hide
          </button>
        </>
      )}
    </span>
  )

  // highlight api key when shown for easy copy/paste
  useEffect(() => {
    Utility.selectContentsByID('api-key')
  }, [keyVisible])

  return (
    <Page title="API Access">
      <Card className="col-12 auto" contentClasses="p-1">
        <h3 className="mb-5">Documentation</h3>
        <div className="mb-3">
          API documentation is available at{' '}
          <a href={apiDocsUrl} rel="noopener noreferrer" target="_blank">
            {apiDocsUrl}
          </a>
          .
        </div>
        <h3 className="mb-5">Support</h3>
        <div className="mb-3">
          <div className="mb-1">
            <span>
              Feel free to reach out to <a href="https://support.hp.com/">HP support</a>.
            </span>
          </div>
        </div>
        <h3 className="mb-5">API Key</h3>
        <div className="mb-3">
          <div className="mb-1">
            <span>This is the private API key for your organization. Do not share it.</span>
          </div>
          <div>{keyElement}</div>
        </div>
      </Card>
    </Page>
  )
}
export {API}
