import {Auth} from 'aws-amplify'
import React from 'react'
import './NoAccess.scss'

interface FunctionComponentProps {}

/**
 * Simple page to indicate no access.
 *
 * @param props
 * @constructor
 */
const NoAccess: React.FC<FunctionComponentProps> = props => {
  const logout = async () => {
    // If logout successful, will be picked up by auth state listener and redirected
    await Auth.signOut().catch(e => {
      console.error('Error signing out', e)
    })
  }

  return (
    <>
      <div className="no-access">
        <p className="warning">
          You have successfully logged in, but you do not have permission to access Poly Zero Touch Onboarding. For assistance, please contact <a href="https://support.hp.com/">HP support</a>.
          <br />
          <br />
          When resolved, please log out and retry.
        </p>
        <button className="button button-sm caution" onClick={logout}>
          <i />
          Log Out
        </button>
      </div>
    </>
  )
}

export {NoAccess}
