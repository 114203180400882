import React from 'react'
import {debounce as _debounce, has as _has, isFunction as _isFunction, noop as _noop} from 'lodash-es'

const TextLikeInput = props => {
  let classes = `form-group ${props.className}`
  // assume valid unless otherwise indicated by props.isValid
  let isValid = true

  // if no input into field, don't consider invalid yet
  const isTouched = props.value
  if (isTouched && _has(props, 'isValid')) {
    classes += props.isValid ? ' valid' : ' invalid'
    isValid = props.isValid
  }

  const isTextArea = props.cols || props.rows

  // set change handler, and optionally debounce interval in milliseconds
  let onChange = props.handleChange || _noop
  if (props.debounce && _isFunction(props.handleChange)) {
    const debounceHandleChange = _debounce(props.handleChange, props.debounce)
    onChange = (e: React.SyntheticEvent) => {
      // @help https://reactjs.org/docs/events.html#event-pooling
      // without e.persist, event is immediately nullified and not accessible in the future
      e.persist()

      debounceHandleChange(e)
    }
  }

  return (
    <div className={classes}>
      {props.title && (
        <label htmlFor={props.name} className="form-label">
          {props.title}
        </label>
      )}
      {isTextArea && (
        <textarea
          className="form-input form-control"
          id={props.name}
          name={props.name}
          value={props.value}
          defaultValue={props.defaultValue}
          onBlur={props.onBlur}
          onChange={onChange}
          maxLength={props.maxLength}
          placeholder={props.placeholder}
          disabled={props.disabled}
          readOnly={props.readOnly}
          autoFocus={props.autoFocus}
        />
      )}
      {!isTextArea && (
        <input
          className="form-input form-control"
          id={props.name}
          name={props.name}
          type={props.type}
          value={props.value}
          defaultValue={props.defaultValue}
          onBlur={props.onBlur}
          onChange={onChange}
          maxLength={props.maxLength}
          pattern={props.pattern}
          placeholder={props.placeholder}
          disabled={props.disabled}
          readOnly={props.readOnly}
          autoFocus={props.autoFocus}
        />
      )}
      {!isValid && props.invalidWarning && <p className="invalid-warning">{props.invalidWarning}</p>}
      {props.children}
    </div>
  )
}

export {TextLikeInput}
