import {LOG_LEVEL as OPTIMIZELY_LOG_LEVEL} from '@optimizely/optimizely-sdk/lib/utils/enums'
import {createInstance, OptimizelyProvider} from '@optimizely/react-sdk'
import {get as _get, set as _set} from 'lodash-es'
import React, {useEffect, useCallback} from 'react'
import Amplify, {Auth, Hub} from 'aws-amplify'
import {useSelector, useDispatch} from 'react-redux'
import {Provider} from 'urql'
import {client} from '../../graphql/graphqlClient'
import {Routes} from '../../routes/routes'
import {CognitoUser} from '@aws-amplify/auth'
import './App.scss'
import '../../styles/Global.scss'
import '../../styles/Mirror.scss'
import {State} from '../../store'
import {changeAuthState, changeUserData} from '../../store/login/actions'
import * as Analytics from '../../utils/Analytics'
import * as config from '../../config'
import {RemoteData} from '../../utils/RemoteData'
import {Utility} from '../../utils/Utility'
import {Notification} from '../../utils/Notification'

interface IComponentProps {}

Amplify.configure(config.amplify)
Analytics.configure()

const sdkKey = Utility.envVariable('REACT_APP_OPTIMIZELY_SDK_KEY')
if (!sdkKey) {
  console.error('No optimizely SDK key found, please update environment.')
}

const optimizely = createInstance({
  sdkKey: sdkKey,
  logLevel: OPTIMIZELY_LOG_LEVEL.WARNING
})

const App: React.FC<IComponentProps> = props => {
  const dispatch = useDispatch()

  const getUser = useCallback(() => {
    Auth.currentAuthenticatedUser()
      .then((data: CognitoUser) => {
        dispatch(changeUserData({data, error: undefined}))
        dispatch(changeAuthState('signedIn'))
      })
      .catch(error => {
        dispatch(changeUserData({data: undefined, error}))
        dispatch(changeAuthState('signIn'))

        // don't warn if user is just not authenticated
        if ('not authenticated' !== error) {
          console.error('Sign in error', error)
        }
      })
  }, [dispatch])

  const getKey = () => {
    const token = RemoteData.getToken()
    if (!token) return

    const axios = require('axios').default
    const config: any = {
      url: RemoteData.getAPIUrl('/key'),
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    axios(config)
      .then(response => {
        const value = response.data ? response.data.value : undefined
        _set(window, ['localStorage', 'apiKey'], value)
      })
      .catch(error => console.error('Failure to retrieve API KEY', error))
  }

  const location = useSelector((state: State) => _get(state, 'router.location'))

  useEffect(() => {
    // run getUser once on lift of component
    if (Utility.getParameterByName('state')) {
      // ensure at least one call, but add a slight delay to help avoid flash of unstyled content on first login
      // while AWS Auth is parsing login code & state
      // NOTE getUser can be called multiple times without problems, it is idempotent
      setTimeout(() => getUser(), 1200)
    } else {
      getUser()
    }
  }, [getUser])

  // TODO watch changeUserData, when have token call getKey
  getKey()

  // let the Hub module listen on Auth events
  // for all events, provide channel / .* / (without spaces)
  Hub.listen('auth', data => {
    switch (data.payload.event) {
      case 'signIn':
        getUser()

        // this will typically trigger before getUser is finished, and may initially be partial data (e.g. not username)
        changeUserData({data: data.payload.data, error: undefined})

        break
      case 'signIn_failure':
        changeUserData({data: undefined, error: data.payload.data})
        changeAuthState('signIn')

        // test for example response http://example.com/?error_description=PreAuthentication+failed+with+error+Invalid+user.+&state=.....&error=invalid_request
        // amplify hides the code/error/error_description/state, so use route in location.search instead
        const error_description =
          Utility.getParameterByName('error_description', location.search) || data.payload.message
        const error = Utility.getParameterByName('error', location.search)

        let message = 'invalid_request' === error ? 'User does not exist. ' : 'Error logging in. '
        message += `Returned error: ${error_description}`
        if (data.payload?.data?.code !== 'NotAuthorizedException') Notification.error(message)

        break
      // signOut is seen on password-less logout, oAuthSignOut on oauth method sign out
      // NOTE oAuthSignOut is included here for consistency, even though
      // Auth.currentAuthenticatedUser().catch will be run automatically after oAuthSignOut
      case 'signOut':
      case 'oAuthSignOut':
        dispatch(changeUserData({data: undefined, error: undefined}))
        dispatch(changeAuthState('signIn'))

        break
      default:
        break
    }
  })

  // email may return undefined or empty string; either way,
  // cast empty values to undefined for purposes of Optimizely ID tracking
  const email = useSelector((state: State) => _get(state, 'login.userData.email') || undefined)
  const authState = useSelector((state: State) => _get(state, 'login.authState'))

  return (
    <Provider value={client}>
      {/* wait for a known authState to render routes */}
      {authState && (
        <OptimizelyProvider optimizely={optimizely} user={{id: email, attributes: {email}}}>
          <Routes />
        </OptimizelyProvider>
      )}
    </Provider>
  )
}

export {App}
