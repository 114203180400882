import React from 'react'
import './Landing.scss'
import {useSelector} from 'react-redux'
import {Card} from '../../components/Card/Card'
import {State} from '../../store'
import configureDevices from '../../assets/images/configure-devices.png'
import apiAccess from '../../assets/images/api-access.png'

interface ComponentProps {}

const Landing: React.FC<ComponentProps> = props => {
  const userData = useSelector((state: State) => state.login.userData)

  return (
    <div className="landing-page">
      <div className="greeting mt-1 mb-2">
        <div className="greeting-name">Hello {userData.firstName}</div>
        {/* <div className="greeting-message">Let's get those devices configured.</div> */}
      </div>
      <div>
        {/*<Card className="landing" to="/devices" card={{"name": "Configure"}}>*/}
        <Card className="landing configure-devices" to="/devices">
          <img src={configureDevices} alt="Configure Devices Illustration" />
          <h6>Configure Devices</h6>
        </Card>
        <Card className="landing api-access" to="/api">
          <img src={apiAccess} alt="API Illustration" />
          <h6>API Access</h6>
        </Card>
      </div>
    </div>
  )
}

export {Landing}
