import React from 'react'
import './Page.scss'

interface ComponentProps {
  title?: string
  className?: string
}

const Page: React.FC<ComponentProps> = props => {
  return (
    <div className="ml-2 mt-3">
      <div className="page-header mb-3">
        {/*<Link to="/" className="return-to-landing">*/}
        {/*  <PolyIcon icon="directional_arrow_left_fill" size="lg"/>*/}
        {/*</Link>*/}
        {props.title && <h1>{props.title}</h1>}
      </div>
      <div className="page-container grid">{props.children}</div>
    </div>
  )
}

export {Page}
