import React from 'react'
import {SignInPane} from '../../components/SignInPane/SignInPane'
import PolyIcon from '../../elements/PolyIcon/PolyIcon'
import './Login.scss'

interface FunctionComponentProps {
  hideButton: boolean
  messageClasses: string
}

/**
 * See Invitation.tsx for example usage with message.
 * Note auth can assume a provider if desired, e.g. `Auth.federatedSignIn({customProvider: 'Microsoft'})`
 *
 * @param props
 * @constructor
 */
const Login: React.FC<FunctionComponentProps> = props => (
  <div className="login">
    <div className="intro">
      <PolyIcon className="polymark" customIcon="poly_mark" size={200} />
      <PolyIcon className="polyname" customIcon="poly_name" overrideDimensions={true} />
      <div className="mirror-wrapper">
        <div className="mirror">
          Zero
          <br />
          Touch
          <br />
          Onboarding
        </div>
      </div>
    </div>
    {props.children && <div className={props.messageClasses}>{props.children}</div>}
    {true !== props.hideButton && <SignInPane />}
  </div>
)

export {Login}
