import React from 'react'
import {Link} from 'react-router-dom'
import PolyIcon, {PolyIconOption} from '../../elements/PolyIcon/PolyIcon'
import './Card.scss'

interface ComponentProps {
  to?: any
  // note key is an internal React variable, so index (same as key) needs to be set on Cards in a list
  index?: number
  contentClasses?: string
  className?: string
  // create standardized h3 element with PolyIcon; if needed, could add customIcon prop of PolyIcon
  headerIcon?: PolyIconOption
  headerText?: string
  card?: {
    name?: string
    labelClassName?: string
    icon?: PolyIconOption
  }
  children: any
  animationSpeed?: 1 | 2
}

const Card: React.FC<ComponentProps> = props => {
  const animationMultiplier = 100 / (props.animationSpeed || 1)
  const animationDelay = (props.index || 0) * animationMultiplier

  const html = (
    <div className={`${props.className} card animation-delay-${animationDelay}`}>
      {props.headerText && (
        <h3>
          {props.headerIcon && <PolyIcon className="mr-25" icon={props.headerIcon} size="sm" />}
          {props.headerText}
        </h3>
      )}
      {props.card && (
        <label className="pr-5 pt-25">
          <PolyIcon
            className={(props.card.labelClassName || '') + ' ml-75 mr-5'}
            icon={props.card.icon}
            title={props.card.name}
            size={25}
          />
          <span className={props.card.labelClassName || ''}>{props.card.name}</span>
        </label>
      )}
      <div className={(props.contentClasses || '') + ' content'}>{props.children}</div>
    </div>
  )

  return props.to ? <Link to={props.to}>{html}</Link> : html
}

export {Card}
