import gql from 'graphql-tag'
import {cacheExchange, createClient, dedupExchange, fetchExchange, useQuery as urqlUseQuery} from 'urql'
import {get as _get} from 'lodash-es'
import {devtoolsExchange} from '@urql/devtools'
import * as config from '../config'

export const client = createClient({
  url: config.amplify.aws_appsync_graphqlEndpoint,
  fetchOptions: () => {
    // TODO consider using asynchronous methods like below, or saving token explicitly on Auth/un-auth
    // const token = (await Auth.currentSession()).getIdToken().getJwtToken()

    // AWS stored in local storage CognitoIdentityServiceProvider.1n6n7v76m4vit1e5r4u6b3ntjo.3378d926-844e-4294-b87a-6e71e53d7aac.idToken
    const prefix = `CognitoIdentityServiceProvider.${config.amplify.aws_user_pools_web_client_id}`
    const lastAuthUser = _get(window, ['localStorage', `${prefix}.LastAuthUser`], '')
    const token = _get(window, ['localStorage', `${prefix}.${lastAuthUser}.idToken`], '')

    return {
      headers: {authorization: token}
    }
  },
  exchanges: [dedupExchange, devtoolsExchange, cacheExchange, fetchExchange]
})

type requestPolicy = 'cache-and-network' | 'cache-first' | 'cache-only' | 'network-only' | undefined

/**
 * Convenience wrapper for urql's useQuery with sane defaults.
 *
 * @param query
 * @param variables
 * @param requestPolicy - cache-and-network, network-only, cache-only etc
 *
 * @help https://github.com/FormidableLabs/urql/blob/master/docs/basics.md#request-policies
 */
export const useQuery = (query: string, variables = {}, requestPolicy: requestPolicy = 'cache-and-network') => {
  // available: fetching, stale, data, error, extensions
  return urqlUseQuery({
    query: gql(query),
    variables,
    requestPolicy
  })
}
