import React, {useState} from 'react'
import './Page.scss'
import PolyIcon from '../../elements/PolyIcon/PolyIcon'
import {Tooltip} from '../Tooltip/Tooltip'

interface ComponentProps {
  value: string
}

const CopyToClipboard: React.FC<ComponentProps> = props => {
  const [successVisible, setSuccessVisible] = useState(false)
  const [errorVisible, setErrorVisible] = useState(false)

  const copy = (text: string) => {
    let listener = (e: ClipboardEvent) => {
      /* tslint:disable no-string-literal */
      let clipboard = e.clipboardData || window['clipboardData']
      clipboard.setData('text', text)
      e.preventDefault()
    }

    document.addEventListener('copy', listener, false)

    try {
      document.execCommand('copy')

      // if code reaches this section, success
      setSuccessVisible(true)
      setTimeout(() => setSuccessVisible(false), 2500)
    } catch (e) {
      console.error(`Could not copy value ${text}`, e)
      setErrorVisible(true)
    }
  }

  return (
    <>
      <button className="button button-icon-only">
        <Tooltip tooltip="Copy">
          <PolyIcon icon="edit_copy" size={16} onClick={() => copy(props.value)} />
        </Tooltip>
      </button>
      {successVisible && (
        <span className="success-highlight ml-25">
          <PolyIcon icon="check" size={16} /> Copied!
        </span>
      )}
      {errorVisible && (
        <span className="warning-highlight ml-25">
          <PolyIcon icon="error" size={16} /> Error Copying
        </span>
      )}
    </>
  )
}

export {CopyToClipboard}
